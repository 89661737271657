//引入、配置路由的相关信息
import Vue from 'vue'
import VueRouter from 'vue-router'
//1.Vue.use安装插件
Vue.use(VueRouter)
const routes = [
    {
        path: '/:id',
        component: () => import('@/views/index.vue'),
    },
]
//2.创建路由对象
const router = new VueRouter({
    // 路由的模式
    mode: 'history',
    //配置路由与组件的关系
    routes,

})
//3.将router对象导出
export default router
